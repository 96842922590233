import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/san-diego-real-estate-market-trends-2024-insights-forecasts"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_What's Next for the San Diego Real Estate Market in 2024_.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Like everyone else in the country, San Diego home buyers and sellers have a burning
                question: What’s going to happen to the real estate market in 2024? While we cannot
                predict where it’s headed with certainty, we can tell you where it is now and where
                we think it'll go throughout the remainder of the year.{" "}
              </CommonParagraph>
              <Heading type="h2">Current San Diego Real Estate Market Trends</Heading>
              <Heading type="h3">Competition</Heading>
              <CommonParagraph>
                If you live in the San Diego area, you already know the market is competitive. But
                just how competitive is it? On average, homes last an average of 15 days on the
                market and receive an average of six offers.
              </CommonParagraph>
              <Heading type="h3">Cost</Heading>
              <CommonParagraph>
                Currently, the median home sale price in San Diego clocks in at $921,753. That’s an
                8.4% increase from last year. On top of that, many homes are selling as much as 5%
                above their listing prices. And to stay competitive, many buyers are waiving
                contingencies.
              </CommonParagraph>
              <Heading type="h3">Inventory</Heading>
              <CommonParagraph>
                With under 3,000 homes on the market, inventory remains low. However, it has
                increased by 13.3% since April. This shortage is driving up prices and making the
                market{" "}
                <BlogLink
                  url="https://selbysellssd.com/how-to-win-a-bidding-war-in-a-competitive-san-diego-market"
                  text="far more competitive"
                />
                .
              </CommonParagraph>
              <Heading type="h3">Interest Rates</Heading>
              <CommonParagraph>
                Current interest rates for a fixed-rate 30-year mortgage are at over 7%. While still
                relatively low from a historical perspective, rates have doubled since 2022,
                impacting buyer and seller behavior.
              </CommonParagraph>
              <CommonParagraph>
                More specifically, many buyers are reluctant to purchase due to higher monthly
                mortgage rates and rising home prices. And would-be sellers are choosing to stay put
                and keep their homes off the market. Why? Because they do not want to take on a
                higher interest rate.
              </CommonParagraph>
              <Heading type="h2">Where’s the San Diego Market Headed?</Heading>
              <CommonParagraph>
                To understand where the market may be headed, you must consider the following:
              </CommonParagraph>
              <Heading type="h3">The Fed and Interest Rates</Heading>
              <CommonParagraph>
                The Federal Reserve does not set mortgage rates. However, mortgage lenders do take
                cues from them. And while the Fed has hinted that rate cuts are coming, experts
                suggest there isn’t enough confidence that inflation will drop toward the 2% target.
                Our best guess? Mortgage rates will not see any meaningful drops in the remainder of
                2024.
              </CommonParagraph>
              <Heading type="h3">Inventory</Heading>
              <CommonParagraph>
                With current homeowners enjoying lower interest rates (secured before 2022), we
                suspect many will choose to remain in their homes rather than sell. Because of this,
                we believe inventory will remain relatively low and competition high.
              </CommonParagraph>
              <Heading type="h3">Affordability</Heading>
              <CommonParagraph>
                Due to a combination of limited inventory, higher interest rates, and elevated home
                prices, we suspect home prices will stay up. Because of this, buyers may need to
                adjust their expectations—either buy less house or explore alternative financing
                options. Or, alternatively, continue to rent or pay down their current mortgages to
                see what happens through the remainder of 2024.
              </CommonParagraph>
              <Heading type="h3">Seller Opportunities</Heading>
              <CommonParagraph>
                High interest rates may discourage some would-be sellers from listing their homes.
                However, others looking to downsize or relocate out of state and into more
                affordable markets are at an advantage.{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home"
                  text="It's a seller's market"
                />
                , and sellers can certainly command higher prices.
              </CommonParagraph>
              <Heading type="h3">Buyer Opportunities</Heading>
              <CommonParagraph>
                Highly coveted neighborhoods may come with premium price tags. However, San Diego
                still offers a wide range of desirable neighborhoods at attractive price points.
                Buyers willing to explore different areas will find hidden gems that provide
                excellent value for the money.{" "}
              </CommonParagraph>
              <CommonParagraph>
                Our experienced realtors have in-depth knowledge of the San Diego market and can
                help you{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/"
                  text="find neighborhoods"
                />{" "}
                that align with your budget and preferences.
              </CommonParagraph>
              <Heading type="h2">Competitive Markets Demand Competitive Realtors</Heading>
              <CommonParagraph>
                Thinking about buying in San Diego? Then you will need a trusted ally by your side.
                <BlogLink url="https://selbysellssd.com/" text="The Selby Team " /> understands the
                local market and can give you the insight and guidance you need to stay competitive
                while making informed decisions that fit your lifestyle and goals.{" "}
                <ContactSlideoutLink text="Connect with us" /> today to get started!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
